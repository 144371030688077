 .app {
  display: flex;
  height: 90vh !important;
  background-color: #dadbd3;
}

.app__body {
  /* display: flex; */
  background-color: #ededed;
  flex: 1;
}

/**********************SideBar******************************/
.sidebar-wtp {
  display: flex;
  flex-direction: column;
  border-right: 1px solid lightgray;
  width: 30%;
  height: 92.5vh;
}

.sidebar__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  border-bottom: 1px solid lightgray;
  background-color: #f6f6f6;
}

.sidebar__headerProfileImage {
  width: 45px;
  height: 45px;
  border-radius: 50%;

  /* background: "gray";
  color: "white";
  display: "flex";
  align-items: "center";
  justify-content: "center";
  border-radius: "50%";
  width: "40px";
  height: "40px";
  font-size: "18px"; */
}

.sidebar__headerRight {
  display: flex;
  align-items: center;
}

.sidebar__headerButton {
  margin-left: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.sidebar__search {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  /* border-bottom: 1px solid lightgray; */
  background-color: white;
}

.sidebar__search input {
  width: 100%;
  border: none;
  outline-width: 0;
  padding: 10px;
  border-radius: 7px;
  background-color: #e6e6e6;
}

.sidebar__chats {
  flex: 1;
  background-color: white;
  max-height: 100% ;
  overflow: scroll;
  scrollbar-width: thin;
}

.sidebar__chats::-webkit-scrollbar {
  display: none;
}

.sidebar__chat {
  display: flex;
  /* justify-content: space-between; */
  padding: 15px 15px;
  border-bottom: 1px solid rgb(240 240 240);
  cursor: pointer;
  cursor: pointer;
}

.sidebar__chat:hover {
  background-color: #f2f2f2;
  border-radius: 8px;
}

/*************************Chat******************************/

.chat {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* position: fixed; */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: white; */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 900; /* Ensure it appears above other content */
}

.chat__header {
  display: flex;
  align-items: center;
  padding: 14.5px 15px;
  /* border-bottom: 1px solid lightgray; */
  background-color: #f6f6f6;
}

.chat__headerImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
}

.chat__headerInfo {
  flex: 1;
}

.chat__headerInfo h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.chat__headerInfo p {
  margin: 0;
  color: gray;
  font-size: 13px;
}

.chat__headerRight {
  display: flex;
  justify-content: flex-end;
}

.chat__headerRight .MuiSvgIcon-root {
  margin-left: 10px;
  cursor: pointer;
}

.chat__body {
  flex: 1;
  padding: 0px 30px;
  /* max-height: 450px; */
  background-image: url("../Assets/Images/chat-background.png");
  /* background-color: #e5ded8; */
  overflow: scroll;
  scrollbar-width: none;
}

.chat__message {
  margin: 0;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 10px;
  width: fit-content;
  max-width: 100%;
  font-size: 15px;
  /* width: max-content; */
  padding-right: 75px;
  position: relative;
}

.chat__message-container--self .chat__message {
  background-color: #dcf8c6; /* Light green color for self messages */
}
.chat__message-container--self {
  align-items: flex-end;
}

.chat__message-container--selfauto .chat__message {
  background-color: #f1f8c6;
}

.chat__message-container--selfauto {
  align-items: flex-end;
}

.chat__footer {
  padding: 10px;
  border-top: 1px solid lightgray;
}

.chat__footer input {
  width: 100%;
  border: none;
  padding: 10px;
  outline-width: 0;
}

.chat__headerButton {
  margin-left: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
}

.chat__message--self {
  background-color: #dcf8c6;
  align-self: flex-end;
  color: rgb(17, 17, 17);
}

.chat__message-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.chat__message-time {
  font-size: 10px;
  color: gray;
  margin-top: 5px;
  text-align: right;
  margin: 5px;
  display: block;
  position: absolute;
  top: 40px;
  right: 0px;
}

.chat__message-type {
  font-size: 6px;
  color: gray;
  /* margin-top: 5px; */
  text-align: right;
  /* margin: 5px; */
  display: block;
  position: absolute;
  top: 40px;
  right: 5px;
  font-weight: 600;
}

.chat-container {
  position: relative;
  background-image: url("../Assets/Images/whatsapp1.png"); /* Adjust path */
  background-size: cover;
  background-position: center;
  height: 93vh;
  width: 70%;
  transition: background 0.3s ease;
}

.chat-container--modal-open {
  background-image: none; /* Hide background image when modal is open */
}

.chat__message-status {
  position: relative;
  display: inline-block;
  font-size: 0.75rem;
  color: #888;
  margin-left: 5px;
}

.chat__message-arrow {
  display: inline-block;
  font-size: 1rem;
  color: #888;
  margin-left: 5px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 15px;
}

.chat__message-menu {
  position: absolute;
  top: 0px;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.chat__message-menu ul {
  list-style-type: none;
  padding: 10px;
  margin: 0;
}

.chat__message-menu li {
  padding: 8px 10px;
  cursor: pointer;
}

.chat__message-menu li:hover {
  background-color: #f5f5f5;
}

/******************************************************/

.Flex-Whatsapp-Body {
  display: flex;
  width: 100%;
  /* background-image: url("../Assets/Images/whatsapp1.png"); */
}

.Right-Chat-body {
  background-image: url("../Assets/Images/whatsapp1.png");
}

.Icon-search-wtp {
  position: absolute;
  margin-left: 10px;
}

.Input-Box-whatsapp {
  padding-left: 30px !important;
}
.Flex-chat-wtp {
  display: flex;
}

.chat-person-name {
  margin: 3px 12px;
  font-size: 15px;
}

.chat-contact {
  margin: 0px;
  margin-top: 5px;
  font-size: 10px;
  color: #686666;
}

.Flex-self-text {
  display: flex;
  justify-content: flex-end;
}

.flex-footer {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items:  center;
  gap:  10px;
  /* position: fixed; */
  width: 100%;
  bottom: 0;
  height: 50px;
  padding: 10px 20px;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.Input-Box-Chat {
  width: 80%;
}

.Icon-margin-send {
  margin: auto;
  font-size: 20px;
  color: #4e4d4d;
}

.Icon-Size-wtp {
  font-size: 20px;
  color: #4e4d4d;
}

/* .Input-text-chatting{
    background-color: #fafafa;
  } */

.Input-text-chatting {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
}

.chat-Body-Whatsapp {
  overflow: scroll;
}

.chat-Body-Whatsapp::-webkit-scrollbar {
  display: none;
}

.Chat_Time_Wtp {
  font-size: 11px;
  font-weight: 600;
  margin: 0px;
  margin-right: 0px;
  color: #0cbe0c;
  /* width: 140px; */
  white-space: pre;
}

.chat-person-last-message {
  font-size: 14px;
  color: gray;
  margin: 0px 12px;
}

.Msg-width-wtp {
  width: 70%;
}

/****************************************************/

.attachment-menu-container {
  position: relative;
}

.attachment-menu {
  position: absolute;
  bottom: 6px; /* Adjust as needed */
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 200px;
  margin-left: 5px;
}

.attachment-menu-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.attachment-menu-item:hover {
  background-color: #f1f1f1;
}

.attachment-menu-item span {
  margin-left: 10px;
  font-size: 15px;
}

/*********************Menu Header Icon*****************************/

.header-menu-container {
  position: relative;
}

.modal-header {
  position: absolute;
  top: 40px; /* Adjust as needed */
  right: 0;
  z-index: 1000;
}

.header-menu {
  position: absolute;
  top: 8px;
  right: 0px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 200px;
  z-index: 1000;
}

.header-menu-item {
  padding: 10px;
  cursor: pointer;
  text-align: left;
  font-size: 15px;
}

.header-menu-item:hover {
  background-color: #f1f1f1;
}

.Icon-header-menu {
  position: relative;
}

.Attach-menu {
  position: absolute;
  bottom: 37px;
  left: -25px;
}

.chat__headerBackButton {
  display: none;
  background: none;
  border: none;
  margin-right: 10px;
  cursor: pointer;
}

.chat__headerBackButton .Icon-Size-wtp {
  font-size: 24px;
  color: #686666;
}

/* .Icon-footer-wtp{
  font-size: 25px;
  color: #4e4d4d;
} */

/**********************************************************/

.chat__footer_mobile {
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-top: 1px solid #ddd;
  background-color: #fff;
}

.footer-icon_mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  color: #000000;
}

.footer-icon_mobile img {
  width: 24px;
  height: 24px;
}

.footer-icon_mobile span {
  font-size: 12px;
}

/**********************************************/

.Icon-footer-wtp {
  font-size: 25px;
  margin-bottom: 3px;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: rgb(27, 27, 27);
}

.Icon-footer-wtp.active {
  background-color: #93f393;
  border-radius: 30px;
  /* height: 15px;
  width: 40px; */
}

.footer span {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  color: white; /* Adjust this color as needed */
}

.sidebar__headerLeft,
.sidebar__headerRight {
  display: flex;
  align-items: center;
}

/* Styling for the WhatsApp name */
.sidebar__headerName {
  font-size: 22px;
  color: #07a907;
  font-weight: 700;
  margin-right: 15px;
}

.Flex-right-Icons {
  display: flex;
}

/* Style for the dropdown menu */
.chat__headerMenu {
  position: absolute;
  top: 65px; /* Adjust based on your header height */
  right: 15px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px;
}

/* Style for each menu item */
.chat__headerMenuItem {
  display: block;
  padding: 12px 16px;
  border-bottom: 1px solid #ddd;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  color: #333;
}

.chat__headerMenuItem:last-child {
  border-bottom: none;
}

/* Hover effect for menu items */
.chat__headerMenuItem:hover {
  background-color: #f5f5f5;
}

/****************************************************/

.Input-Box-Chat {
  flex: 1;
  margin-right: 10px;
}

.chat__sendButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #4e4d4d;
}

.unread-count {
  position: relative;
  right: 10px;
  top: 10px;
  background-color: #5ac03b;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}

.chat__message-media {
  max-width: 100%;
  max-height: 300px;
  object-fit: cover;
  margin-top: 10px;
}

.reply-message-box {
  height: 35px;
  width: 150px;
  color: #696969;
  background-color: #fff;
  box-shadow: inset 5px 5px 15px rgb(72 72 72 / 30%);
  padding: 5px;
  text-align: center;
  border-radius: 10px;
  align-content: center;
}

.msg__body {
  text-align: center;
  position: absolute;
  top: 72px;
}

